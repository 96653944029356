import '@pages/policy/ui/index.css';
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';

import destructionOfPDN from '@pages/policy/files/destruction-of-PDN.pdf';
import processingPersonalData from '@pages/policy/files/processing-personal-data.pdf';
import requestOfTheProvisionInformationFile from '@pages/policy/files/request-of-the-provision-information.pdf';
import reviewOfConsent from '@pages/policy/files/review-of-consent.pdf';
import { Layout } from '@shared/ui/layout';

const centerScrollOptions: ScrollIntoViewOptions = { block: 'center' };

export const Policy = () => {
  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  return (
    <Layout>
      <div className='policy'>
        <h2 className='mb-32 title-40'>
          Политика в отношении обработки ПЕРСОНАЛЬНЫХ ДАННЫХ ООО «Диджитал-Девелопмент»
        </h2>

        <h3 className='content-table-title mb-8'>Содержание</h3>
        <ul className='content-table first-list'>
          <li>
            <ScrollIntoView selector='#termins' scrollOptions={centerScrollOptions}>
              Перечень терминов
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView
              selector='#general-provisions'
              scrollOptions={centerScrollOptions}
            >
              1 Общие положения
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView
              selector='#personal-principles'
              scrollOptions={centerScrollOptions}
            >
              2 Принципы обработки персональных данных
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView
              selector='#processing-personal'
              scrollOptions={centerScrollOptions}
            >
              3 Правовые основания обработки персональных данных
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView selector='#conditions' scrollOptions={centerScrollOptions}>
              4 Порядок и условия обработки персональных данных
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView selector='#permissions' scrollOptions={centerScrollOptions}>
              5 Права субъектов персональных данных
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView
              selector='#processing-personal-data'
              scrollOptions={centerScrollOptions}
            >
              6 Порядок осуществления прав субъектов персональных данных
            </ScrollIntoView>
          </li>
          <li>
            <ScrollIntoView
              selector='#document-applications'
              scrollOptions={centerScrollOptions}
            >
              Приложения. Шаблоны запросов субъекта ПДн
            </ScrollIntoView>

            <ul className='second-list'>
              <li>
                <ScrollIntoView
                  selector='#request-provision'
                  scrollOptions={centerScrollOptions}
                >
                  А.1 Запрос на предоставление информации об обработке ПДн
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  selector='#review-consent'
                  scrollOptions={centerScrollOptions}
                >
                  А.2 Отзыв согласия на обработку ПДн
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView
                  selector='#destruction-pdn'
                  scrollOptions={centerScrollOptions}
                >
                  А.3 Требование об уточнении/уничтожении ПДн
                </ScrollIntoView>
              </li>
              <li>
                <ScrollIntoView selector='' scrollOptions={centerScrollOptions}>
                  А.4 Цели обработки персональных данных субъектов – посетителей сайта
                </ScrollIntoView>
              </li>
            </ul>
          </li>
        </ul>

        <h3 className='termins-title mt-128 mb-8' id='termins'>
          Перечень терминов
        </h3>
        <ul className='termins'>
          <li>
            <strong>Персональные данные</strong> – любая информация, относящаяся к прямо
            или косвенно определенному или определяемому физическому лицу (субъекту
            персональных данных).
          </li>
          <li>
            <strong>Оператор персональных данных</strong> – государственный орган,
            муниципальный орган, юридическое или физическое лицо, самостоятельно или
            совместно с другими лицами организующие и (или) осуществляющие обработку
            персональных данных, а также определяющие цели обработки персональных данных,
            состав персональных данных, подлежащих обработке, действия (операции),
            совершаемые с персональными данными.
          </li>
          <li>
            <strong>Обработка персональных данных</strong> – любое действие (операция) или
            совокупность действий (операций), совершаемых с использованием средств
            автоматизации или без использования таких средств с персональными данными,
            включая сбор, запись, систематизацию, накопление, хранение, уточнение
            (обновление, изменение), извлечение, использование, передачу (распространение,
            предоставление, доступ), обезличивание, блокирование, удаление, уничтожение
            персональных данных.
          </li>
          <li>
            <strong>Автоматизированная обработка персональных данных</strong> – обработка
            персональных данных с помощью средств вычислительной техники.
          </li>
          <li>
            <strong>Распространение персональных данных</strong> – действия, направленные
            на раскрытие персональных данных неопределенному кругу лиц.
          </li>
          <li>
            <strong>Предоставление персональных данных</strong> – действия, направленные
            на раскрытие персональных данных определенному лицу или определенному кругу
            лиц.
          </li>
          <li>
            <strong>Блокирование персональных данных</strong> – временное прекращение
            обработки персональных данных (за исключением случаев, если обработка
            необходима для уточнения персональных данных).
          </li>
          <li>
            <strong>Уничтожение персональных данных</strong> – действия, в результате
            которых становится невозможным восстановить содержание персональных данных в
            информационной системе персональных данных и (или) в результате которых
            уничтожаются материальные носители персональных данных.
          </li>
          <li>
            <strong>Информационная система персональных данных</strong> – совокупность
            содержащихся в базах данных персональных данных и обеспечивающих их обработку
            информационных технологий и технических средств.
          </li>
          <li>
            <strong>Трансграничная передача персональных данных</strong> – передача
            персональных данных на территорию иностранного государства органу власти
            иностранного государства, иностранному физическому лицу или иностранному
            юридическому лицу.
          </li>
        </ul>

        <h3 className='general-provisions-title mt-128 mb-8' id='general-provisions'>
          1 Общие положения
        </h3>
        <ul className='general-provisions'>
          <li>
            1.1 Настоящий документ определяет политику ООО «Диджитал-Девелопмент» в
            отношении обработки персональных данных (далее – ПДн).
          </li>
          <li>
            1.2 ООО «Диджитал-Девелопмент» (далее – Оператор) является оператором ПДн в
            соответствии с законодательством Российской Федерации о ПДн.
          </li>
          <li>
            1.3 Политика в отношении обработки ПДн (далее – Политика) разработана в
            соответствии Федеральным законом Российской Федерации от 27.07.2006 г. №
            152-ФЗ «О персональных данных» (далее – ФЗ «О персональных данных»),
            устанавливающим основные принципы и условия обработки ПДн, права, обязанности
            и ответственность участников отношений, связанных с обработкой ПДн.
          </li>
          <li>
            1.4 Политика распространяется на любое действие (операцию) или совокупность
            действий (операций), совершаемых с ПДн с использованием средств автоматизации
            или без использования таких средств, включая сбор, запись, систематизацию,
            накопление, хранение, уточнение (обновление, изменение), извлечение,
            использование, передачу (распространение, предоставление, доступ),
            обезличивание, блокирование, удаление, уничтожение ПДн.
          </li>
          <li>
            1.5 Политика подлежит пересмотру и, при необходимости, актуализации в случае
            изменений в законодательстве Российской Федерации о ПДн.
          </li>
        </ul>

        <h3 className='personal-principles-title mt-128 mb-8' id='personal-principles'>
          2 Принципы обработки персональных данных
        </h3>
        <ul className='personal-principles'>
          <li>Обработка ПДн Оператором осуществляется на основе следующих принципов:</li>
          <li>1) ПДн обрабатываются на законной и справедливой основе;</li>
          <li>
            2) ПДн обрабатываются до достижения конкретных, заранее определённых и
            законных целей. В Обществе не допускается обработка ПДн, несовместимая с
            целями сбора ПДн;
          </li>
          <li>
            3) не допускается объединение баз данных, содержащих ПДн, обработка которых
            осуществляется в целях, несовместимых между собой;
          </li>
          <li>4) обрабатываются только ПДн, отвечающие заявленным целям их обработки;</li>
          <li>
            5) содержание и объем обрабатываемых ПДн соответствуют заявленным целям
            обработки. Обрабатываемые ПДн не являются избыточными по отношению к
            заявленным целям их обработки;
          </li>
          <li>
            6) при обработке ПДн обеспечивается точность ПДн, их достаточность, а в
            необходимых случаях и актуальность по отношению к целям обработки ПДн.
            Оператор принимает необходимые меры по удалению или уточнению неполных, или
            неточных данных;
          </li>
          <li>
            7) ПДн хранятся в форме, позволяющей определить субъекта ПДн, не дольше, чем
            этого требуют цели обработки ПДн, если срок хранения ПДн не установлен
            федеральным законом либо договором, стороной которого, выгодоприобретателем
            или поручителем по которому является субъект ПДн. ПДн уничтожаются по
            достижении целей обработки или в случае утраты необходимости в достижении этих
            целей, если иное не предусмотрено федеральным законом.
          </li>
        </ul>

        <h3 className='processing-personal-title mt-128 mb-8' id='processing-personal'>
          3 Правовые основания обработки персональных данных
        </h3>
        <ul>
          <li>Оператор обрабатывает ПДн на следующих правовых основаниях:</li>
          <li>
            1) во исполнение федерального законодательства Российской Федерации, а именно:
            <ul className='inner-list'>
              <li>– Трудовой кодекс Российской Федерации от 30.12.2001 № 197-ФЗ;</li>
              <li>
                – Постановление Госкомстата РФ от 05.01.2004 № 1 «Об утверждении
                унифицированных форм первичной учетной документации по учету труда и его
                оплаты»;
              </li>
            </ul>
          </li>
          <li>
            2) в связи с участием лица в конституционном, гражданском, административном,
            уголовном судопроизводстве, судопроизводстве в арбитражных судах;
          </li>
          <li>
            3) для осуществления прав и законных интересов Оператора или третьих лиц либо
            для достижения общественно значимых целей при условии, что при этом не
            нарушаются права и свободы субъекта персональных данных;
          </li>
          <li>
            4) для заключения и исполнения договоров между оператором и субъектом
            персональных данных;
          </li>
          <li>
            5) на основании согласия субъекта на обработку ПДн (в случаях, прямо не
            предусмотренных законодательством Российской Федерации, но соответствующих
            полномочиям Оператора).
          </li>
        </ul>

        <h3 className='conditions-title mt-128 mb-8' id='conditions'>
          4 Порядок и условия обработки персональных данных
        </h3>
        <ul className='conditions'>
          <li>
            4.1 Оператор осуществляет обработку ПДн как с использованием средств
            автоматизации, так и без использования таких средств, включая сбор, запись,
            систематизацию, накопление, хранение, уточнение (обновление, изменение),
            извлечение, использование, передачу (распространение, предоставление, доступ),
            блокирование, удаление, уничтожение ПДн.
          </li>
          <li>
            4.2 Оператор не предоставляет и не раскрывает сведения, содержащие ПДн
            субъектов, третьей стороне без согласия субъекта ПДн, за исключением случаев,
            когда это необходимо в целях предупреждения угрозы жизни и здоровью, а также в
            случаях, установленных федеральными законами.
          </li>
          <li>
            4.3 Согласие субъекта ПДн на обработку его может быть дано субъектом ПДн или
            его представителем в форме, позволяющей подтвердить факт его получения.
          </li>
          <li>
            4.4 Оператор осуществляет передачу ПДн третьим лицам, а также поручает
            обработку ПДн другому лицу.
          </li>
          <li>
            4.5 В случаях поручения обработки ПДн другому лицу Оператор заключает договор
            (далее – поручение) с этим лицом и получает согласие субъекта ПДн, если иное
            не предусмотрено федеральным законом. При этом Оператор в поручении обязует
            лицо, осуществляющее обработку ПДн по поручению Оператора, соблюдать принципы
            и правила обработки ПДн, предусмотренные ФЗ «О персональных данных».
            <br />
            Ответственность перед субъектом ПДн за действия указанного лица несет
            Оператор. Лицо, осуществляющее обработку ПДн по поручению Оператора, несет
            ответственность перед Оператором.
          </li>
          <li>4.6 Оператор не осуществляет трансграничную передачу.</li>
          <li>
            4.7 Оператор обязуется и обязует иные лица, получившие доступ к ПДн, не
            раскрывать третьим лицам и не распространять ПДн без согласия субъекта ПДн,
            если иное не предусмотрено федеральным законом.
          </li>
          <li>
            4.8 Оператор уведомляет субъекта ПДн об обработке ПДн в том случае, если ПДн
            были получены не от субъекта ПДн. Исключение составляют следующие случаи:
            <ul className='inner-list'>
              <li>
                субъект ПДн уведомлен об осуществлении обработки Оператором его ПДн;
              </li>
              <li>
                ПДн получены Оператором в связи с исполнением договора, стороной которого
                либо выгодоприобретателем или поручителем по которому является субъект ПДн
                или на основании федерального закона;
              </li>
              <li>
                Оператор осуществляет обработку ПДн для статистических или иных
                исследовательских целей, если при этом не нарушаются права и законные
                интересы субъекта ПДн.
              </li>
            </ul>
          </li>
          <li>
            4.9 Оператор обеспечивает нахождение на территории Российской Федерации баз
            данных информации, с использованием которых осуществляются сбор, запись,
            систематизация, накопление, хранение, уточнение (обновление, изменение),
            извлечение ПДн граждан Российской Федерации.
          </li>
          <li>
            4.10 В соответствии со статьей 18.1 ФЗ «О персональных данных» Оператор
            принимает меры, необходимые для обеспечения выполнения обязанностей,
            предусмотренных ФЗ «О персональных данных»:
            <ul className='inner-list'>
              <li>1) назначен ответственный за организацию обработки ПДн;</li>
              <li>
                2) приказом руководителя Оператора утверждены Положение об обработке
                персональных данных и другие локальные акты, устанавливающие процедуры,
                направленные на выявление и предотвращение нарушений законодательства
                Российской Федерации в сфере ПДн, а также определяющие для каждой цели
                обработки ПДн, категории субъектов ПДн, содержание обрабатываемых ПДн,
                сроки их обработки и хранения, порядок уничтожения при достижении целей
                обработки или при наступлении иных законных оснований;
              </li>
              <li>
                3) применяются предусмотренные статьей 19 ФЗ «О персональных данных»
                правовые, организационные и технические меры по обеспечению безопасности
                ПДн;
              </li>
              <li>
                4) Оператором организовано проведение периодических проверок с целью
                осуществления внутреннего контроля соответствия обработки ПДн ФЗ «О
                персональных данных» и принятым в соответствии с ним нормативным правовым
                актам;
              </li>
              <li>
                5) произведена оценка вреда, который может быть причинен субъектам
                персональных данных в случае нарушения ФЗ «О персональных данных»;
              </li>
              <li>
                6) осуществляется ознакомление работников Оператора, непосредственно
                осуществляющих обработку ПДн, с положениями законодательства Российской
                Федерации о ПДн (в том числе с требованиями к защите ПДн), локальными
                актами по вопросам обработки ПДн.
              </li>
            </ul>
          </li>
          <li>
            4.11 В соответствии со статьей 19 ФЗ «О персональных данных» Оператор
            принимает меры по обеспечению безопасности ПДн при их обработке:
            <ul className='inner-list'>
              <li>
                1) определены угрозы безопасности ПДн при их обработке в информационных
                системах ПДн;
              </li>
              <li>
                2) применяются организационные и технические меры по обеспечению
                безопасности ПДн при их обработке в информационных системах ПДн,
                необходимые для выполнения требований к защите ПДн, исполнение которых
                обеспечивает установленные Правительством Российской Федерации уровни
                защищенности ПДн;
              </li>
              <li>
                3) применяются прошедшие в установленном порядке процедуру оценки
                соответствия средства защиты информации для нейтрализации актуальных угроз
                безопасности;
              </li>
              <li>
                4) произведена оценка эффективности принимаемых мер по обеспечению
                безопасности ПДн до ввода в эксплуатацию информационной системы ПДн;
              </li>
              <li>5) организован учет машинных носителей ПДн;</li>
              <li>
                6) осуществляется обнаружение фактов несанкционированного доступа к ПДн и
                принятием мер;
              </li>
              <li>
                7) осуществляется восстановление ПДн, модифицированных или уничтоженных
                вследствие несанкционированного доступа к ним;
              </li>
              <li>
                8) установлены правила доступа к ПДн, обрабатываемым в информационной
                системе ПДн, а также обеспечением регистрации и учета всех действий,
                совершаемых с ПДн в информационной системе ПДн;
              </li>
              <li>
                9) осуществляется контроль за принимаемыми мерами по обеспечению
                безопасности ПДн и уровня защищенности информационных систем ПДн.
              </li>
            </ul>
          </li>
          <li>
            4.12 Условием прекращения обработки ПДн может являться достижение целей
            обработки ПДн, истечение срока действия согласия или отзыв согласия субъекта
            ПДн на обработку его ПДн, а также выявление неправомерной обработки ПДн.
          </li>
          <li>
            4.13 При обработке ПДн, осуществляемой без использования средств
            автоматизации, выполняются требования, установленные постановлением
            Правительства Российской Федерации от 15 сентября 2008 г. № 687 «Об
            утверждении Положения об особенностях обработки персональных данных,
            осуществляемой без использования средств автоматизации».
          </li>
          <li>
            4.14 Категории и перечень обрабатываемых персональных данных для каждой цели
            обработки персональных данных, категории субъектов, персональные данные
            которых обрабатываются, способы, сроки их обработки и хранения, порядок
            уничтожения персональных данных при достижении целей их обработки или при
            наступлении иных законных оснований определены в документе «Перечень
            обрабатываемых персональных данных в ООО «Диджитал-Девелопмент».
          </li>
        </ul>

        <h3 className='permissions-title mt-128 mb-8' id='permissions'>
          5 Права субъектов персональных данных
        </h3>
        <ul className='permissions'>
          <li>
            5.1 В соответствии с положениями ФЗ «О персональных данных» субъект ПДн имеет
            следующие права в отношении своих ПДн:
            <ul className='inner-list '>
              <li>
                1) право на получение сведений, касающихся обработки ПДн Обществом:
                <ul className='inner-list'>
                  <li>— подтверждение факта обработки ПДн оператором;</li>
                  <li>— правовые основания и цели обработки ПДн;</li>
                  <li>— применяемые оператором способы обработки ПДн;</li>
                  <li>
                    — наименование и место нахождения оператора, сведения о лицах (за
                    исключением работников оператора), которые имеют доступ к ПДн или
                    которым могут быть раскрыты ПДн на основании договора с оператором или
                    на основании федерального закона;
                  </li>
                  <li>
                    — обрабатываемые ПДн, относящиеся к соответствующему субъекту ПДн,
                    источник их получения, если иной порядок представления таких данных не
                    предусмотрен федеральным законом;
                  </li>
                  <li>— сроки обработки ПДн, в том числе сроки их хранения;</li>
                  <li>
                    — порядок осуществления субъектом ПДн прав, предусмотренных ФЗ «О
                    персональных данных»;
                  </li>
                  <li>
                    — информацию об осуществленной или о предполагаемой трансграничной
                    передаче данных;
                  </li>
                  <li>
                    — наименование или фамилию, имя, отчество и адрес лица,
                    осуществляющего обработку ПДн по поручению оператора, если обработка
                    поручена или будет поручена такому лицу;
                  </li>
                  <li>
                    — иные сведения, предусмотренные ФЗ «О персональных данных» или
                    другими федеральными законами;
                  </li>
                </ul>
              </li>
              <li>
                2) право на ознакомление с ПДн, принадлежащими субъекту ПДн,
                обрабатываемыми оператора;
              </li>
              <li>
                3) право требования от оператора уточнения его ПДн, их блокирования или
                уничтожения, в случае, если ПДн являются неполными, устаревшими
                (неактуальными), неточными, незаконно полученными или не являются
                необходимыми для заявленной цели обработки;
              </li>
              <li>
                4) право на отзыв согласия на обработку ПДн (если такое согласие было дано
                оператору).
              </li>
            </ul>
          </li>
          <li>
            5.2 Если субъект ПДн считает, что оператор осуществляет обработку его ПДн с
            нарушением требований ФЗ «О персональных данных» или иным образом нарушает его
            права и свободы, субъект ПДн вправе обжаловать действия или бездействие
            оператора в уполномоченный орган по защите прав субъектов ПДн или в судебном
            порядке.
          </li>
        </ul>

        <h3 className='personal-data-title mt-128 mb-8' id='personal-data'>
          6 Порядок осуществления прав субъектов персональных данных
        </h3>
        <ul className='personal-data'>
          <li>
            6.1 Субъект ПДн может реализовать свои права на получение сведений, касающихся
            обработки его ПДн оператором путем обращения (лично или через законного
            представителя), либо путем направления письменного запроса по адресу:
            Российская Федерация, 121552, г. Москва, ул. Ярцевская д. 19 помещ. 728 либо
            путем направления запроса на адрес электронной почты{' '}
            <Link to={'mailto:SMLTtech@samolet.ru'}>SMLTtech@samolet.ru</Link> .
          </li>
          <li>
            6.2 В соответствии с частью 3 статьи 14 ФЗ «О персональных данных» запрос
            субъекта ПДн (или его представителя) должен содержать:
            <ul className='inner-list'>
              <li>
                — номер основного документа, удостоверяющего личность субъекта ПДн или его
                представителя, сведения о дате выдачи указанного документа и выдавшем его
                органе;
              </li>
              <li>
                — сведения, подтверждающие участие субъекта ПДн в отношениях с оператором
                (номер договора, дата заключения договора, условное словесное обозначение
                и (или) иные сведения), либо сведения, иным образом подтверждающие факт
                обработки ПДн оператором;
              </li>
              <li>— подпись субъекта ПДн или его представителя.</li>
            </ul>
          </li>
          <li>
            Рекомендуемая форма запроса субъекта приведена в приложении (Приложение А.1 ).
          </li>
          <li>
            6.3 Оператор обязуется безвозмездно предоставить запрашиваемые сведения
            субъекту ПДн или его представителю в доступной форме в течение десяти дней с
            даты обращения или даты получения запроса субъекта ПДн или его представителя
            либо дать в письменной форме мотивированный ответ, содержащий ссылку на
            положения федерального закона (законов), являющиеся основанием для отказа в
            предоставлении информации.
          </li>
          <li>
            6.4 В случае если необходимые сведения были предоставлены субъекту ПДн по его
            запросу, субъект ПДн вправе обратиться повторно к оператору или направить ему
            повторный запрос в целях получения данных сведений не ранее чем через тридцать
            дней после первоначального обращения или направления первоначального запроса,
            если более короткий срок не установлен федеральным законом, принятым в
            соответствии с ним нормативным правовым актом или договором, стороной которого
            либо выгодоприобретателем или поручителем по которому является субъект ПДн.
          </li>
          <li>
            6.5 В срок, не превышающий семи рабочих дней со дня предоставления субъектом
            ПДн или его представителем сведений, подтверждающих, что ПДн, обрабатываемые
            оператором, являются неполными, неточными или неактуальными, оператор
            обязуется внести в них необходимые изменения.
          </li>
          <li>
            6.6 В срок, не превышающий семи рабочих дней со дня представления субъектом
            ПДн или его представителем сведений, подтверждающих, что ПДн, обрабатываемые
            оператором, являются незаконно полученными или не являются необходимыми для
            заявленной цели обработки, оператор обязуется уничтожить такие ПДн.
          </li>
          <li>
            6.7 Оператор обязан уведомить субъекта ПДн или его представителя о внесенных
            изменениях и предпринятых мерах и принять разумные меры для уведомления
            третьих лиц, которым ПДн этого субъекта были переданы.
          </li>
          <li>
            6.8 Субъект ПДн имеет право на отзыв согласия на обработку персональных данных
            (в случае, если такое согласие было дано оператору). Рекомендуемая форма
            заявления об отзыве согласия на обработку ПДн приведена в приложении (А.2 ).
          </li>
          <li>
            6.9 В случае отзыва субъектом ПДн согласия на обработку его ПДн оператор
            обязан прекратить их обработку или обеспечить прекращение такой обработки, и в
            случае, если сохранение ПДн более не требуется для целей обработки ПДн,
            уничтожить ПДн или обеспечить их уничтожение в срок, не превышающий тридцати
            дней с даты поступления указанного отзыва, если иное не предусмотрено
            договором, стороной которого, выгодоприобретателем или поручителем по которому
            является субъект ПДн, иным соглашением между оператором и субъектом ПДн, либо
            если оператор не вправе осуществлять обработку ПДн без согласия субъекта ПДн
            на основаниях, предусмотренных федеральными законами.{' '}
          </li>
          <li>
            6.10 В случае невозможности уничтожения ПДн в течение указанного срока,
            оператор осуществляет блокирование таких ПДн или обеспечивает их блокирование
            и уничтожение в срок не более чем шесть месяцев, если иной срок не установлен
            федеральными законами.
          </li>
        </ul>

        <h3
          className='document-applications-title mt-128 mb-8'
          id='document-applications'
        >
          Приложения
        </h3>
        <ul>
          <li>
            <a
              href={requestOfTheProvisionInformationFile}
              download={'request-of-the-provision-information.pdf'}
              id='request-provision'
            >
              1.1 Запрос на предоставление информации об обработке ПДн
            </a>
          </li>
          <li>
            <a
              href={reviewOfConsent}
              download={'review-of-consent.pdf'}
              id='review-consent'
            >
              1.2 Отзыв согласия на обработку ПДн
            </a>
          </li>
          <li>
            <a
              href={destructionOfPDN}
              download={'destruction-of-PDN.pdf'}
              id='destruction-pdn'
            >
              1.3 Требование об уточнении/уничтожении ПДн
            </a>
          </li>
          <li>
            <a
              href={processingPersonalData}
              download={'processing-personal-data.pdf'}
              id='processing-personal-data'
            >
              Цели обработки персональных данных субъектов персональных данных –
              посетителей сайта
            </a>
          </li>
        </ul>
      </div>
    </Layout>
  );
};
