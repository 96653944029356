export default function ScrollLikeMobile(ref: React.RefObject<HTMLElement>) {
  let left = 0;
  let drag = false;
  let coorX = 0;

  ref.current?.addEventListener('mousedown', function (e: MouseEvent) {
    drag = true;
    ref.current?.classList.add('active');
    if (ref?.current) {
      coorX = e.pageX - ref.current.offsetLeft;
      left = ref.current?.scrollLeft;
      cancelMomentumTracking();
    }
  });

  ref.current?.addEventListener('mouseleave', () => {
    drag = false;
    ref.current?.classList.remove('active');
  });

  ref.current?.addEventListener('mouseup', () => {
    drag = false;
    ref.current?.classList.remove('active');
    beginMomentumTracking();
  });

  ref.current?.addEventListener('mousemove', (e: MouseEvent) => {
    if (!drag) return;
    e.preventDefault();
    if (ref.current) {
      const x = e.pageX - ref.current?.offsetLeft;
      const walk = x - coorX;
      const prevScrollLeft = ref.current?.scrollLeft;
      ref!.current!.scrollLeft = left - walk;
      velX = ref.current?.scrollLeft - prevScrollLeft;
    }
  });

  let velX = 0;
  let momentumID: number;

  function beginMomentumTracking() {
    cancelMomentumTracking();
    momentumID = requestAnimationFrame(momentumLoop);
  }
  function cancelMomentumTracking() {
    cancelAnimationFrame(momentumID);
  }
  function momentumLoop() {
    ref!.current!.scrollLeft += velX;
    velX *= 0.95;
    if (Math.abs(velX) > 0.5) {
      momentumID = requestAnimationFrame(momentumLoop);
    }
  }
  ref.current?.addEventListener('wheel', () => {
    cancelMomentumTracking();
  });
}
