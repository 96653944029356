import { useCallback, useContext, useEffect, useRef } from 'react';

import '@shared/ui/fifthFrame/index.css';
import ScrollLikeMobile from '@shared/helpers/scrollLikeMobile';
import FirstImage from '@shared/ui/fifthFrame/images/first-image.webp';
import SecondImage from '@shared/ui/fifthFrame/images/second-image.webp';
import ThirdImage from '@shared/ui/fifthFrame/images/third-image.webp';
import { ModalContext } from '@shared/ui/layout/context';
import { FileNames, TextTypes } from '@shared/ui/modalForm/enum';

export const FifthFrame = () => {
  const { setModalType, onOpenModal, setFileName } = useContext(ModalContext);

  const scrollableOnPc = useRef<HTMLDivElement>(null);

  useEffect(() => {
    ScrollLikeMobile(scrollableOnPc);
  }, [scrollableOnPc]);

  const onButtonClick = useCallback(
    (type: TextTypes, fileName: FileNames) => {
      return () => {
        setModalType(TextTypes[type]);
        setFileName(fileName);
        onOpenModal();
      };
    },
    [onOpenModal, setFileName, setModalType],
  );

  return (
    <section className='fifth-frame'>
      <div className='main-wrapper'>
        <div className='header-block'>
          <h2>Обзорная презентация по продуктам</h2>
          <button
            onClick={onButtonClick(
              TextTypes.productOverviewForm,
              FileNames.SMLT10D_Overview,
            )}
          >
            Скачать презентацию
          </button>
        </div>

        <div ref={scrollableOnPc} className='mobile-scroll'>
          <div className='custom-card'>
            <img loading='lazy' draggable='false' src={FirstImage} alt='first-image' />
          </div>

          <div className='custom-card'>
            <img loading='lazy' draggable='false' src={SecondImage} alt='second-image' />
          </div>

          <div className='custom-card'>
            <img loading='lazy' draggable='false' src={ThirdImage} alt='third-image' />
          </div>
        </div>
      </div>
    </section>
  );
};
