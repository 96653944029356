import axios from 'axios';

import { HTTPMethod } from '@shared/api/swagger';

export default function axiosQuery(
  method: HTTPMethod,
  url: string,
  data: object,
  headers: object,
  isSuccessSend: boolean | null,
  button?: HTMLButtonElement | null,
) {
  axios({
    method: method,
    url: url + '?nocache=' + new Date().getTime(),
    data: data,
    headers: headers,
  })
    .then(function () {
      if (isSuccessSend) {
        button!.disabled = false;
      }
    })
    .catch(function (error) {
      console.log(error);
    })
    .finally(() => {});
}
