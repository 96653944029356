import { ChangeEvent } from 'react';

export default function PhoneMaskChange(phone: ChangeEvent<HTMLInputElement>) {
  const target = phone.target as HTMLInputElement;

  // Если пользователь добавляет и это не автовставка или ctrl+v
  if (target.value.length > target.defaultValue.length) {
    //Если это не автовставка
    if (target.value.length - 1 == target.defaultValue.length) {
      // Если введена не цифра
      if (target.value[target.value.length - 1].match(/\D/)) {
        target.value = target.defaultValue;
      }

      // Если в начале введено не +7
      if (!target.value.match(/^\+7 /)) {
        const inputedValue = target.value[target.value.length - 1];
        target.value = '+7 ' + inputedValue;
      }

      // Если введено 3 цифры или если введена 4 цифра после удаления
      if (target.value.match(/^\+\d* (\d{3})$/) || target.value.length == 7) {
        const oldDigits = target.value;
        target.value =
          oldDigits[0] +
          oldDigits[1] +
          oldDigits[2] +
          '(' +
          oldDigits[3] +
          oldDigits[4] +
          oldDigits[5] +
          ')' +
          ' - ';
      }

      // Если введено 6 цифр
      if (target.value.match(/^\+\d* (\(\d{3}\)) - (\d{3})$/)) {
        target.value += ' - ';
      }

      // Если введено 8 цифр
      if (target.value.match(/^\+\d* (\(\d{3}\)) - (\d{3}) - (\d{2})$/)) {
        target.value += ' - ';
      }
    } else {
      // Если номер введен, но не форматирован
      if (
        target.value.match(/^\+(\d{11})$/) &&
        !target.value.match(/^\+\d* (\(\d{3}\)) - \d$/)
      ) {
        target.value =
          target.value.slice(0, 2) +
          ' (' +
          target.value.slice(2, 5) +
          ') - ' +
          target.value.slice(5, 8) +
          ' - ' +
          target.value.slice(8, 10) +
          ' - ' +
          target.value.slice(10, 12);
      }
    }

    // Если введено больше 24 цифр
    target.value = target.value.slice(0, 24);
  }

  //Если пользователь удаляет
  if (target.value.length < target.defaultValue.length) {
    // Если удалены последние 2 цифры
    if (target.value.length == 22) {
      target.value = target.value.slice(0, 19);
    }

    // Если удалены последние 4 цифры
    if (target.value.length == 17) {
      target.value = target.value.slice(0, 14);
    }

    // Если удалены последние 5 цифр
    if (target.value.length == 11) {
      target.value = target.value.slice(0, 7);
      target.value = target.value.replace('(', '');
      target.value = target.value.replace(')', '');
    }
  }
}
