import '@shared/ui/sixthFrame/index.css';

import { useEffect, useRef } from 'react';

import ScrollLikeMobile from '@shared/helpers/scrollLikeMobile';
import BuildCenterIcon from '@shared/ui/sixthFrame/images/build-center-icon.png';
import FskIcon from '@shared/ui/sixthFrame/images/fsk-icon.png';
import GuardIcon from '@shared/ui/sixthFrame/images/guard-icon.png';

interface Image {
  src: string;
  alt: string;
  style?: React.CSSProperties;
}

const images: Image[] = [
  {
    src: BuildCenterIcon,
    alt: 'build center icon',
  },
  {
    src: FskIcon,
    alt: 'fsk icon',
  },
  {
    src: GuardIcon,
    alt: 'guard icon',
  },
];

export const SixthFrame = () => {
  const videoDivRef = useRef<HTMLDivElement>(null);
  const cardsSliderRef = useRef<HTMLDivElement>(null);

  const scrollIntoElement = () => videoDivRef.current?.scrollIntoView();

  useEffect(() => {
    ScrollLikeMobile(cardsSliderRef);

    const urlParams = new URLSearchParams(window.location.search);
    if (urlParams?.get('video') !== null) {
      scrollIntoElement();
    }
  }, [cardsSliderRef]);

  return (
    <section className='sixth-frame'>
      <div className='content'>
        <h2>Наши клиенты</h2>
        <div className='cards-wrapper' ref={cardsSliderRef}>
          {images.map((card, index) => {
            return (
              <div className='custom-card' key={index}>
                <img
                  draggable='false'
                  src={card.src}
                  alt={card.alt}
                  style={card.style}
                  loading='lazy'
                />
              </div>
            );
          })}
        </div>

        <div className='big-card' ref={videoDivRef}>
          <iframe
            width='1404'
            height='790'
            src='https://rutube.ru/play/embed/55aab3e8dfd983f2cf0989b04453b95e/?p=03I3PyWhvnivUHknRN6duA'
            allow='clipboard-write; autoplay'
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </section>
  );
};
