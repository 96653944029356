import '@shared/ui/cookie/index.css';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { ROUTES } from '@shared/config';

export const Cookie = () => {
  const [cookieAgreement, setCookieAgreement] = useState<string | null>(null);
  const CookieRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setCookieAgreement(localStorage.getItem('samolet10dCookieAgreement'));
    }
  }, []);

  const clickHandler = useCallback(() => {
    CookieRef!.current!.style.display = 'hidden';
    localStorage.setItem('samolet10dCookieAgreement', 'true');
    setCookieAgreement(localStorage.getItem('samolet10dCookieAgreement'));
  }, []);

  return (
    <section className={`${cookieAgreement ? 'hidden' : ''} cookie`} ref={CookieRef}>
      <div className='main-wrapper'>
        <div className='description'>
          <h4>Мы обрабатываем cookie. </h4>
          <span>
            Оставаясь на сайте Вы соглашаетесь <br className='mobile-br' />
            <Link to={ROUTES.agreementCookie}>с условиями их использования</Link>.
          </span>
        </div>
        <button onClick={clickHandler}>Принять</button>
      </div>
    </section>
  );
};
