import { Link } from 'react-router-dom';

import { Layout } from '@shared/ui/layout';
import '@pages/agreementAd/ui/index.css';

export const AgreementAd = () => {
  return (
    <Layout>
      <div className='agreement-ad'>
        <h2 className='mb-32 title-40'>Согласие на получение рекламы</h2>
        <p>
          Настоящим, действуя свободно, своей волей и в своем интересе, а также
          подтверждая свою дееспособность, я, в соответствии со ст. 18 Федерального закона
          «О рекламе», даю предварительное согласие на получение от ООО «Диджитал
          Девелопмент», ИНН 9731073515 (далее по тексту – <strong>Самолет 10D</strong>)
          <br />
          сообщений и любой иной информации рекламного характера (далее по тексту –
          <strong>Реклама</strong>) посредством:
          <br />
          <ul>
            <li>СМС;</li>
            <li>мессенджеров;</li>
            <li>телефонных звонков;</li>
            <li>электронной почты;</li>
            <li>
              иным образом на предоставленные мною в адрес <strong>Самолет 10D</strong>{' '}
              номер телефона и/или адрес электронной почты.
            </li>
          </ul>
          <br />Я подтверждаю, что уведомлен о следующем:
          <ul>
            <li>
              в случае нежелания получать Рекламу на указанных в согласии условиях мне
              следует снять отметку в поле «Cогласие на получение рекламы» путем нажатия
              на нее;
            </li>
            <li>
              я могу в любое время полностью отказаться от получения Рекламы путем нажатия
              ссылки «отписаться» в конце электронного письма, содержащего информацию
              рекламного характера, направленного на указанный мной адрес электронной
              почты, или направив письмо на почту{' '}
              <Link to={'mailto:SMLTtech@samolet.ru'}>SMLTtech@samolet.ru</Link>.
            </li>
          </ul>
          <br />Я также подтверждаю, что предоставленные мною в адрес Самолет 10D номер
          телефона и/или адрес электронной почты принадлежат мне, а направляемая на них
          Реклама предназначена только для меня и не будет получаться третьими лицами, не
          давшими Самолет 10D предварительного согласия на получение Рекламы.
        </p>
      </div>
    </Layout>
  );
};
