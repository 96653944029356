import { Layout } from '@shared/ui/layout';
import '@pages/thirdParties/ui/index.css';

export const ThirdParties = () => {
  return (
    <Layout>
      <div className='third-parties'>
        <h2 className='mb-32 title-40'>
          Третьи лица, которым Оператор может передавать персональные данные либо поручать
          обработку персональных данных и цели такой передачи/поручения
        </h2>
        <p>
          <table>
            <tr>
              <th>
                <b>Наименование</b>
              </th>
              <th>
                <b>Юридический адрес и ИНН</b>
              </th>
              <th>
                <b>Политика конфиденциальности</b>
              </th>
              <th>
                <b>Цель передачи/поручения</b>
              </th>
            </tr>
            <tr>
              <th>АО «амоЦРМ»</th>
              <th>
                109004, г. Москва, вн.тер.г. муниципальный округ Таганский, ул. Земляной
                вал д. 60, помещ. 1/1, ИНН 7709477879
              </th>
              <th>
                <a href='https://www.amocrm.ru/confidence/'>
                  https://www.amocrm.ru/confidence/
                </a>
              </th>
              <th>
                сбор и хранение данных из заявок посетителей сайта, заполнивших форму
                обратной связи
              </th>
            </tr>
            <tr>
              <th>АО «Интернет-Проекты»</th>
              <th>
                197022, г. Санкт –Петербург, ул. Профессора Попова, д. 23, литер Д,
                помещ.28Н
              </th>
              <th>
                <a href='https://sendsay.ru/about/privacy-policy'>
                  https://sendsay.ru/about/privacy-policy
                </a>
              </th>
              <th>
                автоматизация рекламных рассылок клиентам, сегментация для рекламных
                рассылок, управление рассылками
              </th>
            </tr>
          </table>
        </p>
      </div>
    </Layout>
  );
};
