export enum TextTypes {
  headerForm = 'headerForm',
  documentationForm = 'documentationForm',
  controlQualityForm = 'controlQualityForm',
  digitalPactsForm = 'digitalPactsForm',
  accessibilityForm = 'accessibilityForm',
  productOverviewForm = 'productOverviewForm',
  howWeWorksForm = 'howWeWorksForm',
  digitalizationIndustryForm = 'digitalizationIndustryForm',
  footerForm = 'footerForm',

  managerProcurement = 'managerProcurement',
  monitoring = 'monitoring',
  security = 'security',
  event = 'event',
}

export enum FileNames {
  SMLT10D_Pass = 'SMLT10D_Pass.pdf',
  SMLT10D_Control = 'SMLT10D_Control.pdf',
  SMLT10D_Blueprint = 'SMLT10D_Blueprint.pdf',
  SMLT10D_KS_manager = 'SMLT10D_KS_manager.pdf',
  SMLT10D_Overview = 'SMLT10D_Overview.pdf',
}
