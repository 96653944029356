import { Link } from 'react-router-dom';

import { ROUTES } from '@shared/config';
import { Layout } from '@shared/ui/layout';
import '@pages/consentSecond/ui/index.css';

export const ConsentSecond = () => {
  return (
    <Layout>
      <div className='consent'>
        <h2 className='mb-32 title-40'>
          Согласие на обработку персональных данных (включая cookie)
        </h2>
        <p>
          1. Настоящим, свободно, своей волей и в своем интересе, добровольно,
          информировано и сознательно выражаю свое согласие на автоматизированную
          обработку моих персональных данных (далее – ПДн) ООО «Диджитал Девелопмент» по
          адресу: Российская Федерация, 121552, г. Москва, ул. Ярцевская д. 19 помещ. 728.
          <br />
          2. Обработка моих персональных данных может осуществляться в соответствии с
          перечнем персональных данных и целями, приведенными в таблице:
          <table>
            <tr>
              <th>
                <b>Категория субъектов персональных данных</b>
              </th>
              <th>
                <b>Перечень персональных данных</b>
              </th>
              <th>
                <b>Цели обработки</b>
              </th>
            </tr>
            <tr>
              <th>Пользователи сайта</th>
              <th>
                <ul>
                  <li>IP адрес;</li>
                  <li>сведения о пользовательском устройстве и используемом браузере;</li>
                  <li>
                    сведения о взаимодействии с сайтом и сервисами Оператора (источник
                    перехода на сайт, просматриваемые страницы, взаимодействие с объектами
                    и страницами, параметры сессии, данные о времени посещения и пр.);
                  </li>
                  <li>файлы cookie</li>
                </ul>
              </th>
              <th>
                <ul>
                  персонализация рекламы и предложений с учетом предпочтений Пользователя
                </ul>
              </th>
            </tr>
            <tr>
              <th>Пользователи сайта и клиенты, заполнившие форму обратной связи</th>
              <th>
                <ul>
                  <li>Ф.И.О.;</li>
                  <li>контактный номер телефона;</li>
                  <li>адрес электронной почты;</li>
                  <li>наименование компании</li>
                </ul>
              </th>
              <th>
                Получение сообщений и информации рекламного характера в любой иной форме
                посредством:
                <ul>
                  <li>СМС;</li>
                  <li>мессенджеров;</li>
                  <li>телефонных звонков;</li>
                  <li>электронной почты;</li>
                  <li>
                    иным образом на предоставленные мною в адрес{' '}
                    <strong>Самолет 10D</strong> номер телефона и/или адрес электронной
                    почты.
                  </li>
                </ul>
              </th>
            </tr>
          </table>
          3. Оператор вправе осуществлять обработку моих персональных данных следующими
          способами: сбор, запись, систематизация, накопление, хранение, уточнение
          (обновление, изменение), извлечение, использование, передача (предоставление,
          доступ), блокирование, удаление, уничтожение.
          <br />
          4. Персональные данные Пользователей Сайта могут обрабатываться следующими
          интернет-сервисами: Яндекс.Метрика, Яндекс.Аудитории, Top mail.ru, VK
          retargeting, AdRiver.
          <br />
          5. Пользователь уведомлен, что он, как субъект персональных данных, имеет право
          на получение информации, касающейся обработки его персональных данных. Права
          субъектов персональных данных приведены в{' '}
          <Link to={ROUTES.policy}>Политике обработки персональных данных</Link>.
          <br />
          6. убъект персональных данных вправе отозвать свое согласие на обработку
          персональных данных:
          <br />
          6.1 Отзыв согласия на обработку персональных данных может быть направлен
          Оператору по адресу электронной почты{' '}
          <Link to={'mailto:SMLTtech@samolet.ru'}>SMLTtech@samolet.ru</Link>
          <br />
          6.2 В уведомлении об отзыве Субъектом персональных данных своего согласия на
          обработку персональных данных должны быть указаны ФИО, контактный номер
          телефона, а также явно выраженная воля на отзыв Субъектом персональных данных
          своего согласия на обработку персональных данных.
          <br />
          7. Персональные данные Субъекта персональных данных хранятся в течение всего
          срока действия пользовательских соглашений с данным Субъектом персональных
          данных, а также в течение 5 лет после прекращения их действия, если персональные
          данные не были отозваны лично Субъектом персональных данных.
          <br />
          8. Субъект персональных данных считается давшим настоящее Согласие в полном
          объеме без каких-либо изъятий с того момента, как он осуществил создание учётной
          записи Пользователя в программном обеспечении и/или сервисе, сайте Оператора,
          поставив отметку в специальном месте на странице создания учетной записи рядом
          со ссылкой на текст настоящего Согласия, а в отношении файлов cookies – с
          момента подтверждения в электронной форме.
        </p>
      </div>
    </Layout>
  );
};
