import BranchIcon from '@shared/ui/secondFrame/images/branch.svg';
import DigitalCertificatesIcon from '@shared/ui/secondFrame/images/digitalCertificates.svg';
import DocumentationIcon from '@shared/ui/secondFrame/images/documentation.svg';
import Logo from '@shared/ui/secondFrame/images/logo.svg';
import QualityControlIcon from '@shared/ui/secondFrame/images/qualityControl.svg';

import '@shared/ui/secondFrame/index.css';

export const SecondFrame = () => {
  return (
    <section className='second-frame' id='aboutSystem'>
      <h1 className='title mb-64'>
        Комплексное решение <br></br> для <span>автоматизации</span> стройки
      </h1>
      <div className='mobile-scroll'>
        <div className='cards-container'>
          <div className='logo-wrapper'>
            <div className='logo'>
              <span className='logo-text'>самолет</span>
              <img loading='lazy' src={Logo} alt='logo icon' />
            </div>
          </div>

          <div className='cards-wrapper'>
            <div className='custom-card'>
              <img
                loading='lazy'
                className='mb-32'
                src={DocumentationIcon}
                alt='documentaion control icon'
              />
              <h4>Управление рабочей документацией</h4>
              <h4 className='mobile-title'>Документация</h4>
              <p>
                Актуальная рабочая документация <br></br> от проектирования до
                строительства
              </p>
            </div>
            <div className='custom-card'>
              <img
                loading='lazy'
                className='mb-32'
                src={QualityControlIcon}
                alt='quality control icon'
              />
              <h4>Контроль качества строительства</h4>
              <h4 className='mobile-title'>Контроль качества</h4>
              <p>
                Система фиксации и отслеживания замечаний на всех этапах строительства
              </p>
            </div>
          </div>

          <div className='cards-wrapper'>
            <div className='custom-card'>
              <img
                loading='lazy'
                className='mb-32'
                src={BranchIcon}
                alt='branch accessibility icon'
              />
              <h4>Цифровой контроль доступа на объекты</h4>
              <h4 className='mobile-title'>Цифровой контроль</h4>
              <p>
                Решение для цифровизации <br></br> контрольно-пропускного режима
              </p>
            </div>
            <div className='custom-card'>
              <img
                loading='lazy'
                className='mb-32'
                src={DigitalCertificatesIcon}
                alt='digital certificates icon'
              />
              <h4>Цифровые акты выполненных работ</h4>
              <h4 className='mobile-title'>Цифровые акты</h4>
              <p>
                Сдача объемов и подготовка актов <br></br> КС-2, КС-3, КС-6
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
