import { Navigate, RouteObject } from 'react-router-dom';

import { AgreementAd } from '@pages/agreementAd/ui';
import AgreementCookie from '@pages/agreementCookie';
import ConsentFirst from '@pages/consentFirst';
import ConsentSecond from '@pages/consentSecond';
import { Home } from '@pages/home/ui';
import { Policy } from '@pages/policy/ui';
import { Product } from '@pages/product/ui';
import { Products } from '@pages/products/ui';
import { ResearchJune } from '@pages/researchJune/ui';
import { ThirdParties } from '@pages/thirdParties/ui';
import { openapi } from '@shared/api';
import { ROUTES } from '@shared/config';

export const routes: RouteObject[] = [
  {
    path: ROUTES.home,
    Component: Home,
  },
  {
    path: ROUTES.consentFirst,
    Component: ConsentFirst,
  },
  {
    path: ROUTES.consentSecond,
    Component: ConsentSecond,
  },
  {
    path: ROUTES.thirdParties,
    Component: ThirdParties,
  },
  {
    path: ROUTES.policy,
    Component: Policy,
  },
  {
    path: ROUTES.researchJune,
    Component: ResearchJune,
  },
  {
    path: ROUTES.agreementAd,
    Component: AgreementAd,
  },
  {
    path: ROUTES.agreementCookie,
    Component: AgreementCookie,
  },
  {
    path: ROUTES.products,
    Component: Products,
    loader: async () => {
      return openapi.v1DocsFilesProductsList();
    },
  },
  {
    path: ROUTES.product,
    Component: Product,
    loader: async ({ params }) => {
      return openapi.v1DocsFilesProductsRetrieve({ id: Number(params.id) });
    },
    errorElement: <Navigate to={ROUTES.products} />,
  },
];
