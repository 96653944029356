import { useCallback, useContext, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import ScrollIntoView from 'react-scroll-into-view';

import { ROUTES } from '@shared/config';
import { ModalContext } from '@shared/ui/layout/context';

import Logo from '/images/logo.svg';

import '@shared/ui/navigation/index.css';
import { TextTypes } from '@shared/ui/modalForm/enum';
import DropDownChevronIcon from '@shared/ui/navigation/images/dropdown-chevron.svg';

const centerScrollOptions: ScrollIntoViewOptions = { block: 'center' };

export const Navigation = () => {
  const { setModalType, onOpenModal } = useContext(ModalContext);

  const chevronImageRef = useRef<HTMLImageElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const hash = location.hash;

    if (hash) {
      const element = document.getElementById(hash.substring(1));

      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, []);

  const mobileClickHandler = useCallback(() => {
    if (!matchMedia('(pointer:fine)').matches) {
      dropdownContentRef.current?.classList.toggle('dropdown-toggle');

      if (chevronImageRef.current!.style.rotate == '0deg') {
        chevronImageRef.current!.style.rotate = '180deg';
        dropdownContentRef.current!.style.pointerEvents = 'none';
      } else {
        chevronImageRef.current!.style.rotate = '0deg';
        dropdownContentRef.current!.style.pointerEvents = 'all';
      }
    }
  }, []);

  const openModalWithTextType = useCallback(() => {
    setModalType(TextTypes.headerForm);
    onOpenModal();
  }, [setModalType, onOpenModal]);

  return (
    <nav className='navigation'>
      <Link to={ROUTES.home}>
        <img src={Logo} alt='logo' />
      </Link>
      <div>
        <ScrollIntoView selector='#aboutSystem' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#aboutSystem`}>
            <span className='text-14'>О системе</span>
          </Link>
        </ScrollIntoView>
        <div className='dropdown'>
          <button className='dropbtn' onClick={mobileClickHandler}>
            Решения
            <img
              ref={chevronImageRef}
              className='dropdown-chevron'
              src={DropDownChevronIcon}
              alt='drop down chevron'
            />
          </button>
          <div className='dropdown-content' ref={dropdownContentRef}>
            <ScrollIntoView selector='#documentation' scrollOptions={centerScrollOptions}>
              Рабочая документация
            </ScrollIntoView>
            <ScrollIntoView
              selector='#qualityControl'
              scrollOptions={centerScrollOptions}
            >
              Контроль качества
            </ScrollIntoView>
            <ScrollIntoView selector='#digitalPacts' scrollOptions={centerScrollOptions}>
              КС-Менеджер
            </ScrollIntoView>
            <ScrollIntoView selector='#accessibility' scrollOptions={centerScrollOptions}>
              Контроль доступа на объекты
            </ScrollIntoView>
          </div>
        </div>

        <ScrollIntoView selector='#contacts' scrollOptions={centerScrollOptions}>
          <Link className='link' to={`${ROUTES.home}#contacts`}>
            <span className='text-14'>Контакты</span>
          </Link>
        </ScrollIntoView>
        <button className='text-14' onClick={openModalWithTextType}>
          Заказать демо
        </button>
      </div>
    </nav>
  );
};
