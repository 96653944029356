import { Link } from 'react-router-dom';

import { Layout } from '@shared/ui/layout';
import '@pages/agreementCookie/ui/index.css';

export const AgreementCookie = () => {
  return (
    <Layout>
      <div className='agreement-cookie'>
        <h2 className='mb-32 title-40'>Политика использования Cookie</h2>
        <p>
          ООО «ДИДЖИТАЛ ДЕВЕЛОПМЕНТ» (далее - Оператор) обрабатывает пользовательские
          данные, указанные в настоящей Политике, собранные в процессе пользования
          посетителями нашего сайта. При нажатии кнопки «Принять» в Окне-уведомлении об
          обработке cookie, Вы даете свое согласие на обработку пользовательских данных.
          <br />
          Пользовательские данные - технические данные, которые передаются устройством
          Пользователя, в том числе: IP-адрес, информация, сохраненная в файлах cookie,
          информация о браузере, операционная система на устройстве Пользователя,
          количество просмотренных страниц, длительность пребывания на сайте, запросы,
          которые Пользователь использовал при переходе на сайт, страницы, с которых были
          совершены переходы, сведения о мобильном устройстве, в т.ч. идентификатор
          устройства, идентификатор сессии и иная техническая информация, передаваемая
          устройством.
          <br />
          Состав Пользовательских данных для обработки может отличаться в зависимости от
          используемого устройства и программного обеспечения на устройстве Пользователя.
          <br />
          Сookie — это небольшой фрагмент данных, который отправляется веб-сервером и
          хранится на компьютере Пользователя. Cookie содержат информацию о Ваших
          действиях на сайте, а также могут содержать сведения о Вашем оборудовании, дату
          и время сессии. Сookie хранятся локально на Вашем компьютере или мобильном
          устройстве.
          <br />
          Cookie используются Оператором в целях:
          <br />
          <ul>
            <li>улучшения и обеспечения работоспособности сайта;</li>
            <li>ведения статистики о пользователях;</li>
            <li>хранения персональных предпочтений и настроек пользователей;</li>
            <li>отслеживания состояния сессии доступа пользователей;</li>
            <li>совершенствования продуктов и услуг Оператора,</li>
          </ul>
          <br />
          Наш сайт обрабатывает полученные данные, в том числе, с использованием
          метрических программ и систем аналитики, таких как Яндекс.Метрика.
          <br />
          Мы используем как сессионные, так и постоянные файлы cookie.
          <br />
          Сессионные - временные файлы cookie, существующие во временной памяти устройства
          Пользователя в течение времени, когда Пользователь использует сайт. Устройства
          обычно удаляют сессионные cookie после того, как Пользователь закрывает браузер.
          <br />
          Постоянные - используются для запоминания предпочтений Пользователя при
          использовании сайта и остаются на устройстве Пользователя и после закрытия
          браузера или перезагрузки устройства Пользователя.
          <br />
          Используются следующие типы cookie:
          <ul>
            <li>
              технические файлы cookie, которые необходимы для правильной работы и
              предоставления полного функционала сайта;
            </li>
            <li>
              аналитические и маркетинговые файлы cookie, которые позволяют распознавать
              пользователей, подсчитывать их количество и собирать информацию, например, о
              произведенных операциях, посещенных страницах, идентифицировать аппаратное и
              программное обеспечение Пользователя (тип браузера и устройство), собирать
              иную информацию;
            </li>
            <li>
              прочие файлы cookie, которые выполняют различные служебные функции и делают
              удобнее работу с сайтом, например, сохраняя персональные предпочтения.
            </li>
          </ul>
          <br />
          Файлами cookie Пользователь может управлять самостоятельно. Используемый
          Пользователем браузер и (или) устройство может позволять блокировать, удалять
          или иным образом ограничивать использование файлов cookie, при этом Оператор не
          гарантирует корректность работы сайта.
          <br />
          Если вы хотите отказаться от некоторых файлов cookie Яндекс.Метрика - перейдите
          по ссылке и воспользуйтесь Блокировщиком Яндекс.Метрики:
          <br />
          <br />
          <span className='yandex-blocker'>
            Блокировщик Яндекс.Метрики:{' '}
            <Link to={'https://yandex.ru/support/metrica/general/opt-out.html'}>
              https://yandex.ru/support/metrica/general/opt-out.html
            </Link>
          </span>
        </p>
      </div>
    </Layout>
  );
};
